/** @jsxImportSource theme-ui */
import { useTranslation } from 'react-i18next';

import { Link } from 'gatsby';
import { Box } from 'theme-ui';

import useAuth from '../../../hooks/useAuth';
import Text from '../../01_atoms/Text/Text';

interface Props {
  isOpen: boolean;
}

function Menu({ isOpen }: Props) {
  const { t } = useTranslation();

  const { handleSignOut } = useAuth();

  return (
    <Box
      sx={{
        position: 'fixed',
        zIndex: 9,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
        transition: 'transform ease-in 0.3s',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      style={{
        transform: isOpen ? 'translateY(0)' : 'translateY(-100%)',
      }}
    >
      <Link to="/app" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          {t('HOME')}
        </Text>
      </Link>

      <Link to="/app/code" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          Kody dostępu
        </Text>
      </Link>

      <Link to="/app/users" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          Uzytkownicy korzystający z aplikacji w ramach B2B
        </Text>
      </Link>
      <Link to="/app/courses" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          Kursy
        </Text>
      </Link>
      <Link to="/app/instructors" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          Trenerzy
        </Text>
      </Link>
      <Link to="/app/analytics" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          Dane klientów
        </Text>
      </Link>
      <Link to="/app/sessions" sx={{ textDecoration: 'none' }}>
        <Text
          variant="h2"
          sx={{
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 0,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              mt: 5,
              fontSize: '24px !important',
            },
          }}
        >
          Sesje
        </Text>
      </Link>

      <Box
        onClick={(): void => {
          handleSignOut();
        }}
      >
        <Text
          variant="h2"
          sx={{
            cursor: 'pointer',
            display: 'inline-block',
            letterSpacing: '-0.04px',
            fontSize: '28px',
            textTransform: 'uppercase',
            mt: 8,
            mb: 2,
            '@media (max-height: 576px) and (orientation: landscape)': {
              fontSize: '24px !important',
              mt: 4,
              mb: 1,
            },
          }}
        >
          Wyloguj
        </Text>
      </Box>
    </Box>
  );
}

export default Menu;
